import ButtonLarge from "@/components/shared/ButtonLarge";
import CircleButton from "@/components/shared/CircleButton";
import { LogEventProperties, MixpanelEventName } from "@/types";
import { CloseIcon, SearchIcon } from "@phiaco/phia-ui/dist/icons";
import cn from "classnames";

interface Props {
  eventName: MixpanelEventName;
  eventProperties?: LogEventProperties;
  className?: string;
  children: React.ReactNode;
  onDismiss?: () => void;
  onClick?: () => void;
}

const DismissableItem: React.FC<Props> = ({
  eventName,
  eventProperties,
  className,
  children,
  onDismiss,
  onClick,
}) => (
  <ButtonLarge
    eventName={eventName}
    eventProperties={eventProperties}
    variant="secondary"
    className={cn(
      "h-fit bg-bg-secondary text-cn-disabled hover:bg-overlay-inverse100",
      className
    )}
    innerClassName="bg-pink"
    onClick={onClick}>
    <div className="flex w-full items-center">
      <SearchIcon className="pointer-events-none mr-3 text-cn-tertiary " />
      <span className="max-w-full truncate" title={children as string}>
        {typeof children === "string" && children.length > 33
          ? `${children.slice(0, 33)}...`
          : children}
      </span>
      {onDismiss && (
        <CircleButton
          isTiny
          variant="transparent"
          eventName={MixpanelEventName.SEARCH_OVERLAY_RECENT_SEARCH_REMOVED}
          onClick={e => {
            e.stopPropagation();
            onDismiss();
          }}
          innerClassName="text-cn-tertiary"
          className="ml-auto h-fit w-fit">
          <CloseIcon />
        </CircleButton>
      )}
    </div>
  </ButtonLarge>
);

export default DismissableItem;
