import useAnalytics from "@/hooks/useAnalytics";
import { LogEventProperties, MixpanelEventName } from "@/types";
// eslint-disable-next-line no-restricted-imports
import { Dropdown as SharedDropdown } from "@phiaco/phia-ui";
import {
  DropdownOption,
  DropdownProps,
} from "@phiaco/phia-ui/dist/components/Dropdown/Dropdown";
import * as React from "react";
import NoSSR from "../NoSSR";

interface Props {
  eventName: MixpanelEventName | null;
  eventProperties?: LogEventProperties;
}

const Dropdown: React.FC<Props & DropdownProps> = ({
  eventName,
  eventProperties,
  onOptionSelected,
  ...props
}) => {
  const { logMixpanelEvent } = useAnalytics();

  return (
    <NoSSR>
      <SharedDropdown
        onOptionSelected={(option: DropdownOption) => {
          onOptionSelected?.(option);
          if (eventName) {
            logMixpanelEvent({
              name: eventName,
              properties: {
                ...eventProperties,
                selected_option: { label: option?.label, value: option?.value },
              },
            });
          }
        }}
        {...props}>
        {props.children}
      </SharedDropdown>
    </NoSSR>
  );
};

export default Dropdown;
