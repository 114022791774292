import { Platform } from "@/__generated__/graphql";
import Link from "@/components/shared/Link";
import { VERCEL_BLOB_URL } from "@/constants";
import { useAuthContext } from "@/context/AuthContext";
import useAnalytics from "@/hooks/useAnalytics";
import { useSigninModal } from "@/hooks/useSigninModal";
import { useShowSignInModalState } from "@/lib/graphql/state";
import { routes } from "@/routes";
import { MixpanelEventName } from "@/types";
import { SupportedAuthProvider } from "@/utils/auth";
import { CloseIcon } from "@phiaco/phia-ui";
import { isNotNullOrUndefined } from "@phiaco/phia-ui/dist/util";
import { CodeResponse } from "@react-oauth/google";
import Image from "next/image";
import { useRouter } from "next/navigation";
import { useCallback, useState } from "react";
import GoogleSignInButton from "./GoogleSignInButton";
import CircleButton from "./shared/CircleButton";

export default function SignInContent(props: { onboardingSource: Platform }) {
  const { onboardingSource } = props;
  const { logMixpanelEvent } = useAnalytics();
  const { signInWithGoogleAuthCode, signInWithGoogleForPreviewDeployments } =
    useAuthContext();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState<{
    [key in SupportedAuthProvider]?: boolean;
  }>({});

  const [error, setError] = useState<string | null>(null);

  const handleError = useCallback(
    (message: string | null) => {
      setError(message);
      logMixpanelEvent({
        name: MixpanelEventName.LOGIN_ERROR,
        // Using message (the parameter) instead of error (which might be outdated or null) ensures accurate logging.
        properties: { reason: message },
      });
    },
    [logMixpanelEvent]
  );

  const handleLoadingChange = (
    provider: SupportedAuthProvider,
    isLoading: boolean
  ) => {
    setIsLoading(prev => ({ ...prev, [provider]: isLoading }));
  };

  // const handleSignInWithApple = async () => {
  //   const provider = "apple";
  //   try {
  //     handleLoadingChange(provider, true);
  //     await signInWithApple(onboardingSource, onSuccess);
  //     handleError(null);
  //   } catch (e: any) {
  //     handleError(e.message as string);
  //     console.error(e);
  //   }
  //   handleLoadingChange(provider, false);
  // };

  // If there's a pending search query move on with that, otherwise route back to the home page
  const { source } = useShowSignInModalState();
  const { onCloseSignInModal } = useSigninModal();
  const onSuccess = () => {
    if (isNotNullOrUndefined(source)) {
      router.push(source);
    } else {
      router.push(routes.HOME);
    }
  };

  const handleGoogleSignInWithAuthCode = async (codeResponse: CodeResponse) => {
    const provider = "google";
    try {
      handleLoadingChange(provider, true);
      await signInWithGoogleAuthCode(
        codeResponse.code,
        onboardingSource,
        onSuccess
      );
      handleError(null);
    } catch (error) {
      if (error instanceof Error) {
        handleError(error.message);
      }
      console.error(error);
    }
    handleLoadingChange(provider, false);
  };

  const handleGoogleSignInForPreview = async () => {
    const provider = "google";
    try {
      handleLoadingChange(provider, true);
      await signInWithGoogleForPreviewDeployments(onboardingSource, onSuccess);
      handleError(null);
    } catch (error) {
      if (error instanceof Error) {
        handleError(error.message);
      }
      console.error(error);
    }
    handleLoadingChange(provider, false);
  };

  return (
    <div className="relative flex items-center sm:min-w-[500px] lg:min-h-[480px] lg:min-w-[800px]">
      <div className="absolute right-xl top-xl flex w-fit items-center justify-end">
        <CircleButton
          // Event name is null because logging already happens in onCloseSignInModal
          eventName={null}
          variant="secondary"
          customPadding="p-1.5"
          onClick={onCloseSignInModal}
          className="h-[1.75rem] w-[1.75rem]">
          <CloseIcon size={16} />
        </CircleButton>
      </div>

      <div className="flex w-full flex-col items-center justify-end pt-[3.625rem] lg:pr-[60px] lg:pt-0">
        <div className="flex w-full flex-col items-center gap-8 text-center lg:ml-auto lg:max-w-[335px]">
          <div className="flex w-full flex-col items-center gap-8 px-xl">
            <div className="heading-serif-lg">Sign in to Phia</div>
            <div className="flex w-full flex-col items-center gap-3 md:w-[345px] md:items-start">
              <GoogleSignInButton
                handleError={() => {
                  handleError(
                    "An error occured. Please try again or contact support@phia.com."
                  );
                }}
                handleGoogleSignInForPreview={handleGoogleSignInForPreview}
                handleGoogleSignInWithAuthCode={handleGoogleSignInWithAuthCode}
                isLoading={isLoading.google ?? false}
              />
              {/* [PHI-1732 : Re-enable Apple SSO](https://linear.app/phiaco/issue/PHI-1732/re-enable-apple-sso) */}
              {/* <AppleSignInButton
                    handleSignIn={handleSignInWithApple}
                    isLoading={isLoading.apple ?? false}
                  />
              */}
              {error && (
                <div className="paragraph-md text-cn-negative">{error}</div>
              )}
            </div>
            <div className="flex gap-2">
              <div className="label-md text-cn-tertiary">
                Don&apos;t have an account?{" "}
              </div>
              <Link
                eventName={MixpanelEventName.SIGN_UP_FROM_SIGN_IN_MODAL_CLICKED}
                href={routes.MARKETPLACE_ONBOARDING_GET_STARTED}
                className="label-md text-cn-accent">
                Sign up →
              </Link>
            </div>
          </div>
        </div>
        {/* Desktop Image */}
        <div className="absolute bottom-0 left-0 hidden h-[425px] w-[370px] lg:block">
          <Image
            src={`${VERCEL_BLOB_URL}/Signin%20Modal/desktop-tHBpQmtjcexhr7HkHOfsTH40zToWFF.png`}
            alt="Designer bag"
            className="h-full w-full object-contain object-left"
            fill
          />
        </div>
        {/* Tablet Image */}
        <div className="relative hidden h-[375px] w-[393px] sm:block lg:hidden">
          <Image
            src={`${VERCEL_BLOB_URL}/Signin%20Modal/Tablet-fPuPKyF5wIAwukbUHBmFJ0RbQduyvW.png`}
            alt="Designer bag"
            className="h-full w-full object-contain object-bottom"
            fill
          />
        </div>
        {/* Mobile Image */}
        <div className="relative mt-8 h-[385px] w-full sm:hidden">
          <Image
            src={`${VERCEL_BLOB_URL}/Signin%20Modal/mobile-il9PJsQG6rs2GFjTHTCHXNa6TJnvI0.png`}
            alt="Designer bag"
            className="h-full w-full object-contain object-bottom"
            fill
          />
        </div>
      </div>
    </div>
  );
}
