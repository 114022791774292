import { Category } from "@/__generated__/graphql";
import { zIndex } from "@/constants";
import useCategoryData from "@/hooks/useGetCategoryMenu";
import { MixpanelEventName } from "@/types";
import { CloseIcon, Dialog, useTailwindBreakpoint } from "@phiaco/phia-ui";
import { customCN as cn } from "@phiaco/phia-ui/dist/util";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@radix-ui/react-navigation-menu";
import { useState } from "react";
import CircleButton from "../shared/CircleButton";
import CategoryOverlayMenu from "./CategoryOverlayMenu";

interface CategoryMenuProps {
  className?: string;
  triggerClassName?: string;
  overlayClassName?: string;
  contentClassName?: string;
}

const CategoryMenu: React.FC<CategoryMenuProps> = ({
  className,
  overlayClassName,
  triggerClassName,
  contentClassName,
}) => {
  const { categoryData, isLoading, error } = useCategoryData();
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [openDialog, setOpenDialog] = useState(false);
  const { isMobile } = useTailwindBreakpoint();

  if (isLoading || !categoryData.length || error) {
    return null;
  }

  const handleTriggerClick = (category: Category) => {
    if (isMobile) {
      setSelectedCategory(category);
      setOpenDialog(true);
    }
  };

  return (
    <NavigationMenu
      className={cn(
        "pointer-events-auto relative flex w-full flex-col items-center [&>div]:flex [&>div]:w-full [&>div]:justify-center",
        className
      )}
      style={{ zIndex: zIndex.CATEGORY_MENU }}
      id="hide">
      <NavigationMenuList className="no-scrollbar flex max-w-full flex-row gap-3 overflow-x-auto whitespace-nowrap px-4">
        {categoryData.map(category => {
          if (!category || !category?.label) {
            return null;
          }
          return (
            <NavigationMenuItem key={category.label}>
              <NavigationMenuTrigger
                className={cn(
                  "label-sm relative h-fit cursor-default rounded-full bg-bg-secondary px-5 py-1.5 text-cn-tertiary hover:bg-bg-tertiary data-[state=open]:bg-bg-tertiary",
                  triggerClassName
                )}
                onClick={() => handleTriggerClick(category)}>
                {category.label}
              </NavigationMenuTrigger>
              {!isMobile && (
                <NavigationMenuContent
                  className={cn(
                    "absolute left-0 top-0 w-full",
                    contentClassName
                  )}>
                  <CategoryOverlayMenu
                    category={category}
                    overlayClassName={overlayClassName}
                    placement="menu"
                  />
                </NavigationMenuContent>
              )}
            </NavigationMenuItem>
          );
        })}
      </NavigationMenuList>
      {isMobile && selectedCategory && (
        <Dialog.Root open={openDialog} onOpenChange={setOpenDialog}>
          <Dialog.Portal>
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
            <Dialog.Content
              className="fixed inset-0 flex items-center justify-center overflow-auto bg-white"
              style={{ zIndex: zIndex.HEADER + 1 }}>
              <Dialog.Close
                className="absolute right-4 top-4"
                style={{ zIndex: zIndex.HEADER + 2 }}>
                <CircleButton
                  eventName={MixpanelEventName.CATEGORY_MENU_CLOSED_ON_MOBILE}
                  className="h-7 w-7"
                  variant="secondary">
                  <CloseIcon className="h-3 w-3" />
                </CircleButton>
              </Dialog.Close>
              <div className="h-full w-full overflow-auto">
                <CategoryOverlayMenu
                  category={selectedCategory}
                  overlayClassName={overlayClassName}
                  placement="menu"
                  onClose={() => setOpenDialog(false)}
                />
              </div>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      )}
    </NavigationMenu>
  );
};

export default CategoryMenu;
