import { Image, ShowcasedPage } from "@/__generated__/graphql";
import { routes } from "@/routes";

export const getFeaturedPageHref = (
  page: ShowcasedPage
): string | undefined => {
  const pageType = page.__typename;

  switch (pageType) {
    case "BrandPage":
      return routes.BRAND + page.slug;
    case "PartnerPage":
      return routes.PARTNER + page.slug;
    case "EditorialPage":
      return routes.EDITORIALS + page.slug;
    case "CategoryPage":
      return routes.CATEGORIES + page.slug;
    default:
      return undefined;
  }
};

export const getShowcasedPageImage = (
  page: ShowcasedPage
): Image | undefined => {
  const pageType = page.__typename;

  switch (pageType) {
    case "BrandPage":
    case "PartnerPage":
      return page.imagesCollection.items[0];
    case "EditorialPage":
    case "CategoryPage":
      return page.image ? (page.image as Image) : undefined;
    default:
      return;
  }
};

export const getShowcasedPageLabel = (
  page: ShowcasedPage
): string | undefined => {
  const pageType = page.__typename;

  switch (pageType) {
    case "BrandPage":
    case "PartnerPage":
    case "CategoryPage":
      return page.heading;
    case "EditorialPage":
      return page && page.label ? page.label : undefined;
    default:
      return;
  }
};
