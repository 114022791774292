"use client";

import { routes } from "@/routes";
import { MixpanelEventName } from "@/types";
import { useTailwindBreakpoint } from "@phiaco/phia-ui";
import { memo } from "react";
import Link from "./shared/Link";

interface Props {
  className?: string;
  fill?: string;
}

const Logo: React.FC<Props> = ({ className, fill = "black" }) => {
  const { isMobile } = useTailwindBreakpoint();

  const width = isMobile ? "36" : "61";
  const height = isMobile ? "20" : "34";

  return (
    <Link eventName={MixpanelEventName.NAV_LOGO_CLICKED} href={routes.HOME}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 61 34`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}>
        <path
          d="M30.7584 26.4461C28.8815 26.4461 28.8485 24.6488 29.3095 22.6881L31.1865 14.4205C31.6146 12.6232 31.9438 10.8259 30.6267 10.8259C28.1646 10.8259 24.0011 17.9062 22.279 25.9795C22.2698 26.0228 22.2313 26.054 22.1867 26.054H18.9548C18.8949 26.054 18.8502 25.9994 18.8625 25.9412L23.3498 4.7477C23.7716 2.71923 23.4582 1.99147 22.378 1.68919C22.3518 1.68186 22.3364 1.65428 22.345 1.62868L22.3775 1.53188C22.3883 1.49976 22.4157 1.47593 22.4492 1.46957L27.5643 0.499512H27.5796C27.6396 0.499512 27.6843 0.554302 27.6718 0.612489L24.0576 17.5041C24.0458 17.5592 24.1258 17.5841 24.149 17.5327C26.5965 12.1186 29.3629 8.53839 32.7341 8.53839C35.4014 8.53839 35.4013 11.2834 34.611 14.6819L32.8 22.4594C32.6354 23.1456 32.4378 24.0279 32.9646 24.0279C33.3406 24.0279 34.1108 23.4628 35.1681 22.1907C35.2022 22.1497 35.2642 22.1443 35.3043 22.1797L35.4648 22.3212C35.4985 22.3509 35.5061 22.4005 35.4831 22.4389C33.9665 24.9765 32.3242 26.4461 30.7584 26.4461Z"
          fill={fill}
        />
        <path
          d="M42.742 6.04112C41.5236 6.04112 40.5687 5.09344 40.5687 3.88434C40.5687 2.70792 41.5236 1.76025 42.742 1.76025C43.9274 1.76025 44.8823 2.70792 44.8823 3.88434C44.8823 5.09344 43.9274 6.04112 42.742 6.04112Z"
          fill={fill}
        />
        <path
          d="M38.7576 26.4324C36.8148 26.4324 36.9465 24.2756 37.3087 22.6744L39.482 12.5114C39.6467 11.7925 39.8113 10.9429 39.2844 10.9429C38.9268 10.9429 38.3164 11.4903 37.4724 12.4707C37.4335 12.5158 37.3626 12.5145 37.3258 12.4677L37.2026 12.311C37.1792 12.2811 37.1763 12.2398 37.1953 12.2069C38.5346 9.8893 40.1294 8.52467 41.5894 8.52467C43.4334 8.52467 43.3676 10.3547 42.9725 12.2827L40.7992 22.4457L40.7928 22.475C40.6911 22.945 40.4809 23.9162 40.9967 23.9162C41.4543 23.9162 42.2651 23.1956 43.1413 22.1755C43.1745 22.1368 43.2328 22.1311 43.2727 22.163L43.4579 22.3112C43.4943 22.3403 43.5035 22.3918 43.4797 22.4317C41.9699 24.9653 40.351 26.4324 38.7576 26.4324Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.6334 20.3851C52.6081 24.5552 50.4845 26.4268 47.9134 26.4268C45.8364 26.4268 44.388 24.6285 44.388 21.4243C44.388 15.5063 48.6077 8.50926 54.8675 8.50926C56.4431 8.50926 59.375 9.47989 60.1934 10.3776C60.2309 10.4187 60.2431 10.4751 60.2315 10.5294L57.6843 22.4436C57.5823 22.9475 57.3328 23.9162 57.8598 23.9162C58.3173 23.9162 59.2256 23.1956 60.1018 22.1755C60.135 22.1368 60.1934 22.1311 60.2333 22.163L60.4185 22.3112C60.4549 22.3403 60.464 22.3918 60.4403 22.4317C58.9304 24.9653 57.3115 26.4324 55.7181 26.4324C55.2075 26.4324 54.839 26.2828 54.5797 26.0331C53.8525 25.3329 53.9847 23.8457 54.2517 22.6653L54.7292 20.4143C54.7412 20.3586 54.6565 20.3328 54.6334 20.3851ZM55.9561 14.1893C54.8029 19.9439 51.5854 23.9365 49.4109 23.9365C48.2578 23.9365 47.4717 23.0073 47.4717 20.6532C47.4717 15.4218 50.9152 9.52829 54.2745 9.52829C56.6513 9.52829 56.309 12.5256 55.9561 14.1893Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.1023 8.52641C16.9254 8.52641 18.7553 11.1731 18.7553 14.9309C18.7553 20.5511 14.7356 26.4327 8.56026 26.4327C8.02907 26.4327 6.99532 26.3436 6.30549 26.1086C6.2537 26.0909 6.19748 26.1218 6.1863 26.1754C6.1863 26.1754 6.1863 26.1754 6.18629 26.1754C6.18621 26.1759 6.17135 26.2678 5.36858 30.099C4.92972 32.1935 4.65695 33.108 5.79417 33.1961C5.82769 33.2013 5.85212 33.2325 5.84663 33.266L5.81654 33.4498C5.81184 33.4785 5.78707 33.4995 5.75802 33.4995H0.205798C0.169201 33.4995 0.14134 33.4667 0.147287 33.4306L0.186244 33.2323C0.191259 33.2019 0.215585 33.1782 0.24597 33.1728C1.27646 33.0299 1.33113 32.5986 1.8673 30.1159C3.26554 23.6413 4.38364 18.4707 5.75861 12.116C5.76706 12.0769 5.72751 12.0449 5.6911 12.0614C5.12347 12.3189 4.54345 12.6114 3.93708 12.939C3.88409 12.9676 3.81797 12.942 3.79822 12.8851L3.65815 12.4817C3.64449 12.4423 3.65735 12.3986 3.69021 12.373C4.44265 11.7865 5.19962 11.2596 5.97117 10.7924C6.01767 10.7642 6.05052 10.7181 6.06165 10.6649L6.51295 8.50878C6.52095 8.47057 6.54724 8.43874 6.58325 8.42367L10.1186 6.94452C10.1627 6.92607 10.2094 6.9647 10.1995 7.01147L9.78601 8.97001C9.77842 9.00596 9.81182 9.03708 9.8472 9.02716C10.9649 8.71366 12.0804 8.52641 13.1023 8.52641ZM15.3892 15.2249C15.3892 20.2897 12.5408 25.4215 9.18114 25.4215C6.81457 25.4215 6.87832 22.8606 7.22054 21.2373C9.41395 10.8329 9.43377 10.739 9.43378 10.7389C9.43378 10.7389 9.43378 10.7389 9.43378 10.7389C9.44112 10.7036 9.4674 10.6752 9.50208 10.6653C10.2629 10.4482 10.8965 10.3548 11.5343 10.3548C14.1484 10.3548 15.3892 12.0227 15.3892 15.2249Z"
          fill={fill}
        />
      </svg>
    </Link>
  );
};

export default memo(Logo);
