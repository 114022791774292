"use client";

import Link from "@/components/shared/Link";
import { GIVEAWAY_TOOLTIP_COOKIE_NAME, zIndex } from "@/constants";
import { useAuthContext } from "@/context/AuthContext";
import { useSearch } from "@/context/SearchContext"; // Import the new useSearch hook

import useAnalytics from "@/hooks/useAnalytics";
import { useHomeRevamp } from "@/hooks/useNewHomepage";
import { useSigninModal } from "@/hooks/useSigninModal";
import { useShowSignInModalState } from "@/lib/graphql/state";
import { routes } from "@/routes";
import { MixpanelEventName, SigninModalSource } from "@/types";
import {
  DotsThreeVerticalIcon,
  GiftIcon,
  ProfilePicture,
} from "@phiaco/phia-ui";
import { DropdownOption } from "@phiaco/phia-ui/dist/components/Dropdown/Dropdown";
import cn from "classnames";
import Cookies from "js-cookie";
import { usePathname, useRouter } from "next/navigation";
import React, { useEffect, useState } from "react";
import Button from "../shared/Button";
import Dropdown from "../shared/Dropdown";
import GiveawayTooltip from "./GiveawayTooltip";
import SignInModal from "./SignInModal";
import SignOutModal from "./SignOutModal";

const NUM_DAYS_UNTIL_EXPIRES = 14;

const NavMenu = ({
  isLandingPageScrolled,
  setIsLeaveFeedbackOpen,
}: {
  isLandingPageScrolled: boolean;
  setIsLeaveFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const { logMixpanelEvent } = useAnalytics();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const isLandingPage = pathname === routes.HOME;
  const isSearchPage = pathname === routes.PRODUCTS;
  const { hideSearchOverlay, isSearchOverlayVisible } = useSearch(); // Get search overlay state and hide function
  const { logout, isLoggedIn, user } = useAuthContext();
  const [isGiveawayTooltipVisible, setIsGiveawayTooltipVisible] =
    useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const showSignInModal = useShowSignInModalState();

  const handleLogout = async () => {
    logMixpanelEvent({
      name: MixpanelEventName.SIGN_OUT_CONFIRMED,
    });
    setIsLoggingOut(true);
    await logout();
    setShowSignOutModal(false);
    setIsLoggingOut(false);
  };

  const { handleSignIn, onCloseSignInModal, handleJoinClicked } =
    useSigninModal(SigninModalSource.NavMenu);

  const handleDropdownChange = (selectedOption: DropdownOption) => {
    if (selectedOption.value === "preferences") {
      router.push(routes.PREFERENCES);
    }
    if (selectedOption.value === "profile") {
      router.push(routes.PROFILE);
    }
    if (selectedOption.value === "logout") {
      setShowSignOutModal(true);
    }
    if (selectedOption.value === "giveaway") {
      router.push(routes.GIVEAWAY);
    }
    if (selectedOption.value === "about") {
      router.push(routes.ABOUT_PHIA);
    }
    if (selectedOption.value === "sign_in") {
      handleSignIn();
    }
    if (selectedOption.value === "join") {
      handleJoinClicked();
    }
    // Hide tooltip when click on option
    setIsGiveawayTooltipVisible(false);
  };

  // Check if the cookie is present
  const giveawayTooltipHidden = Cookies.get(GIVEAWAY_TOOLTIP_COOKIE_NAME);
  // Set Visibility of Tooltip Programmatically
  useEffect(() => {
    if (pathname === routes.PROFILE && giveawayTooltipHidden === undefined) {
      setIsGiveawayTooltipVisible(true);
      // Set the cookie with a duration of 2 weeks
      Cookies.set(GIVEAWAY_TOOLTIP_COOKIE_NAME, "true", {
        expires: NUM_DAYS_UNTIL_EXPIRES,
      });
    }
  }, [pathname, giveawayTooltipHidden]);

  const { isRevampActive } = useHomeRevamp();
  // Using the context state to determine if the search overlay is visible
  if (isSearchOverlayVisible && !isRevampActive) {
    return (
      <Button
        eventName={MixpanelEventName.SEARCH_OVERLAY_CLOSE_BUTTON_CLICKED}
        variant="transparent"
        className="mr-4 mt-1 text-sm text-cn-primary"
        onClick={hideSearchOverlay} // Calling hideSearchOverlay instead of routing back
      >
        Close
      </Button>
    );
  }

  const dropdownOptions = !isLoggedIn
    ? [
        { label: "About Phia", value: "about" },
        { label: "Sign in", value: "sign_in" },
        { label: "Join", value: "join" },
      ]
    : [
        { label: "My profile", value: "profile" },
        { label: "Search preferences", value: "preferences" },
        { label: "Giveaway", value: "giveaway", icon: <GiftIcon /> },
        {
          label: "Sign out",
          value: "logout",
          className: "text-cn-negative",
        },
      ];

  if (!isLoggedIn) {
    return (
      <div className="flex items-center gap-2">
        <Button
          onClick={() => {
            router.push(routes.ABOUT_PHIA);
          }}
          eventName={MixpanelEventName.NAV_ABOUT_PHIA_CLICKED}
          variant="tertiary"
          innerClassName="text-cn-primary"
          className="hidden xl:flex">
          About Phia
        </Button>
        <Button
          onClick={handleSignIn}
          variant="tertiary"
          eventName={MixpanelEventName.NAV_SIGN_IN_CLICKED}
          innerClassName="text-cn-primary"
          className="hidden sm:flex">
          Sign in
        </Button>
        <Button
          onClick={handleJoinClicked}
          variant="primary"
          eventName={MixpanelEventName.NAV_JOIN_CLICKED}>
          Join
        </Button>
        <SignInModal
          isOpen={showSignInModal.show}
          onClose={onCloseSignInModal}
        />
        <Dropdown
          open={isDropdownOpen}
          setOpen={setIsDropdownOpen}
          eventName={MixpanelEventName.NAV_MENU_DROPDOWN_SELECTED}
          options={dropdownOptions}
          alignEdge="right"
          className="h-[32px] w-[32px] justify-center bg-white transition-all hover:bg-overlay-inverse100 sm:hidden"
          onOptionSelected={handleDropdownChange}
          zIndex={zIndex.HEADER + 1}>
          <DotsThreeVerticalIcon size={24} />
        </Dropdown>
      </div>
    );
  }

  return (
    <div className="flex items-end gap-4" style={{ zIndex: zIndex.HEADER + 1 }}>
      <Button
        innerClassName="label-sm sm:label-md "
        className={cn("sm:mr-2", {
          // Dont hide feedback button on landing page. Once we migrate to the search bar
          // moving to header on scroll we will need to remove this.
          "sm:hidden": !isLandingPage,
          // When scrolled on homepage hide below xl
          "hidden xl:block":
            isLandingPageScrolled || pathname === routes.GIVEAWAY,
          // Hide entirely on search / products page
          hidden: isSearchPage,
        })}
        variant="secondary"
        eventName={MixpanelEventName.NAV_SUBMIT_FEEDBACK_CLICKED}
        onClick={() => setIsLeaveFeedbackOpen(true)}>
        Give feedback
      </Button>
      {user && (
        <Link
          eventName={MixpanelEventName.NAV_AVATAR_CLICKED}
          href={routes.PROFILE}>
          <ProfilePicture
            size="sm"
            firstName={user?.firstName ?? ""}
            lastName={user?.lastName ?? ""}
            email={user?.email ?? ""}
            imageUrl={user?.imageUrl ?? undefined}
            className="shadow-shallowBelow"
          />
        </Link>
      )}
      <div className="relative flex justify-center">
        <Dropdown
          // passing dropdown state props here to avoid un-necessary behvaiour by radix-ui (it was causing tooltip not to close when click on trigger)
          open={isDropdownOpen}
          setOpen={setIsDropdownOpen}
          eventName={MixpanelEventName.NAV_MENU_DROPDOWN_SELECTED}
          options={dropdownOptions}
          alignEdge="right"
          className="h-[32px] w-[32px] justify-center bg-white transition-all hover:bg-overlay-inverse100"
          onOptionSelected={handleDropdownChange}
          zIndex={zIndex.HEADER + 1}>
          <DotsThreeVerticalIcon size={24} />
        </Dropdown>
        <GiveawayTooltip
          // Hide tooltip when tooltip is false & dropdown is shown
          isVisible={isGiveawayTooltipVisible && !isDropdownOpen}
          setIsVisible={setIsGiveawayTooltipVisible}
        />
      </div>
      <SignOutModal
        onSignOut={handleLogout}
        isOpen={showSignOutModal}
        isLoading={isLoggingOut}
        onClose={() => setShowSignOutModal(false)}
      />
    </div>
  );
};

export default NavMenu;
