import {
  ReportBugMutation,
  ReportBugMutationVariables,
} from "@/__generated__/graphql";
import { reportUserBugMutation } from "@/api/reportBug";
import { WEBSITE_FEEDBACK_SUBJECT, zIndex } from "@/constants";
import useAnalytics from "@/hooks/useAnalytics";
import { MixpanelEventName } from "@/types";
import { useMutation } from "@apollo/client";
import { HeartIcon, Modal, TextArea } from "@phiaco/phia-ui";
import { customCN as cn } from "@phiaco/phia-ui/dist/util";
import React, { useState } from "react";
import ButtonLarge from "./shared/ButtonLarge";

interface FeedbackModalProps {
  onClose: () => void;
  isOpen?: boolean;
  className?: string;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  isOpen = false,
  onClose,
  className,
}) => {
  const [feedback, setFeedback] = useState("");
  const { logMixpanelEvent } = useAnalytics();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [reportBugMutation] = useMutation<
    ReportBugMutation,
    ReportBugMutationVariables
  >(reportUserBugMutation);

  const handleSubmit = async (): Promise<void> => {
    logMixpanelEvent({
      name: MixpanelEventName.FEEDBACK_SUBMITTED,
      properties: { feedback },
    });
    try {
      await reportBugMutation({
        variables: {
          report: {
            text: feedback,
            subject: WEBSITE_FEEDBACK_SUBJECT,
          },
        },
      });
      setIsSubmitted(true);
    } catch (err) {
      console.error("[Error reporting feedback]:", err);
    }
  };

  const handleClose = () => {
    logMixpanelEvent({
      name: MixpanelEventName.FEEDBACK_CLOSED,
      properties: { unsubmitted_feedback: feedback },
    });
    onClose();
    setFeedback("");
    setIsSubmitted(false);
  };

  return (
    <Modal
      zIndex={zIndex.MODAL_OVERLAY}
      isOpen={isOpen}
      heading="Give Feedback"
      includeCloseBtn={true}
      className={cn("w-full sm:max-w-[400px]", className)}
      onClose={handleClose}>
      <div className="relative flex h-full min-h-[300px] flex-col justify-center gap-4 sm:gap-8">
        {isSubmitted ? (
          <div className="flex flex-col items-center justify-center gap-4">
            <div className="flex">
              <HeartIcon size={20} />
            </div>
            <div className="para-md text-center">
              Thank you for helping us improve Phia!
            </div>
          </div>
        ) : (
          <>
            <div className="para-md text-center">
              Tell us what you love and where we can improve as we prepare for
              our official launch.
            </div>
            <TextArea
              placeholder="Your feedback here..."
              className="resize-none"
              onChange={value => setFeedback(value)}
              rows={6}
              value={feedback}
            />
            <ButtonLarge
              // Event logged in event handler
              eventName={null}
              variant="secondary"
              disabled={!feedback?.length}
              className="w-full"
              onClick={handleSubmit}>
              Submit
            </ButtonLarge>
          </>
        )}
      </div>
    </Modal>
  );
};

export default FeedbackModal;
