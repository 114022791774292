import { SearchOverlay as SearchOverlayType } from "@/__generated__/graphql";
import useAnalytics from "@/hooks/useAnalytics";
import { useLinkPasting } from "@/hooks/useLinkPasting";
import { MixpanelEventName } from "@/types";
import React, { memo, useEffect, useState } from "react";
import Container from "../../Container";
import { getRecentSearches } from "../utils";
import BrandGrid from "./BrandGrid";
import EditorialGrid from "./EditorialsGrid";
import SearchLists from "./SearchLists";

const SearchOverlay: React.FC<{
  searchOverlay: SearchOverlayType;
}> = ({ searchOverlay }) => {
  const [recentSearches, setRecentSearches] = useState(getRecentSearches());
  const { logUserEventToMixpanel } = useAnalytics();

  // Log impression on load
  useEffect(() => {
    logUserEventToMixpanel({
      name: MixpanelEventName.SEARCH_OVERLAY_TRIGGERED,
      properties: {
        recent_searches: recentSearches,
        popular_searches: searchOverlay?.popularSearchTerms,
        popular_looks: searchOverlay?.editorialsCollection.items.map(
          edit => edit.slug
        ),
        popular_brands: searchOverlay?.brandsCollection.items.map(
          brand => brand.slug
        ),
        content_id: searchOverlay?.sys.id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Disable background scrolling when overlay is visible
  useEffect(() => {
    // Disable background scrolling when this mounts
    document.body.style.overflow = "hidden";
    return () => {
      // Re-enable background scrolling when unmounts
      document.body.style.overflow = "";
    };
  }, []);

  // Keep recent searches in sync
  useEffect(() => {
    const handleStorageChange = () => {
      const updatedRecentSearches = getRecentSearches();
      setRecentSearches(updatedRecentSearches);
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const savedSearches: string[] = [];

  const { isLinkPastingEnabled } = useLinkPasting();

  const brands = searchOverlay?.brandsCollection.items ?? [];
  const editorials = searchOverlay?.editorialsCollection.items ?? [];
  const popularSearches = searchOverlay?.popularSearchTerms ?? [];

  return (
    <div className="pointer-events-auto mx-auto overflow-y-auto">
      <Container className="h-[calc(100vh_-_7.5rem)] min-h-[unset] max-w-[900px] gap-4 md:h-screen">
        <SearchLists
          savedSearches={savedSearches}
          popularSearches={popularSearches ? (popularSearches as string[]) : []}
          recentSearches={recentSearches}
          className="hidden sm:flex"
        />
        {editorials && editorials.length && !isLinkPastingEnabled ? (
          <EditorialGrid
            heading={searchOverlay.editorialsHeading ?? ""}
            editorials={editorials}
          />
        ) : null}
        <SearchLists
          savedSearches={savedSearches}
          popularSearches={popularSearches ? (popularSearches as string[]) : []}
          recentSearches={recentSearches}
          className="flex sm:hidden"
        />
        {brands && brands.length && !isLinkPastingEnabled ? (
          <BrandGrid
            heading={searchOverlay.brandsHeading ?? ""}
            brands={brands}
          />
        ) : null}
      </Container>
    </div>
  );
};

export default memo(SearchOverlay);
