import useAnalytics from "@/hooks/useAnalytics";
import { MixpanelEventName } from "@/types";
import { GoogleButton } from "@phiaco/phia-ui";
import { CodeResponse, useGoogleLogin } from "@react-oauth/google";

interface GoogleSignInButtonProps {
  handleGoogleSignInWithAuthCode: (codeResponse: CodeResponse) => void;
  handleGoogleSignInForPreview: () => void;
  handleError: () => void;
  isLoading: boolean;
  className?: string;
  textClassName?: string;
}

const scope =
  "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email";

const GoogleSignInButton: React.FC<GoogleSignInButtonProps> = ({
  handleGoogleSignInWithAuthCode,
  handleGoogleSignInForPreview,
  handleError,
  isLoading,
  className,
  textClassName,
}) => {
  const { logAnonymousEventToMixpanel } = useAnalytics();
  const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === "preview";

  const handleSignInWithGoogle = useGoogleLogin({
    onSuccess: handleGoogleSignInWithAuthCode,
    onError: handleError,
    flow: "auth-code",
    select_account: true,
    scope,
  });

  return (
    <GoogleButton
      onClick={e => {
        e.preventDefault();
        if (isPreview) {
          handleGoogleSignInForPreview();
        } else {
          handleSignInWithGoogle();
        }
        logAnonymousEventToMixpanel({
          name: MixpanelEventName.LOGIN_WITH_GOOGLE_CLICKED,
        });
      }}
      type="button"
      className={className}
      textClassName={textClassName}
      isLoading={isLoading}
    />
  );
};

export default GoogleSignInButton;
