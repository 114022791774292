import { ProductSearchParams } from "@/app/products/_types";
import { useLinkPasting } from "@/hooks/useLinkPasting";
import { routes } from "@/routes";
import { MixpanelEventName } from "@/types";
import { resetCacheForQuery } from "@/utils/resetCache";
import { useApolloClient } from "@apollo/client";
import { Label } from "@phiaco/phia-ui";
import cn from "classnames";
import { useRouter } from "next/router";
import { isValidUrl } from "../SearchBar/utils";
import { removeRecentSearch } from "../utils";
import DismissableItem from "./DismissableItem";

const MAX_SEARCH_ITEMS = 3;

interface SearchListColumnProps {
  items: Array<string>;
  title: string;
  onDismiss?: (searchTerm: string) => void;
  onClick?: (searchTerm: string) => void;
  eventName: MixpanelEventName;
}

const SearchListColumn: React.FC<SearchListColumnProps> = ({
  items,
  title,
  onDismiss,
  onClick,
  eventName,
}) => (
  <div className="flex w-full flex-1 flex-col sm:max-w-[400px]">
    <Label size="md" className="pb-4">
      {title}
    </Label>
    {items.map((item, index) => (
      <DismissableItem
        key={index}
        eventName={eventName}
        eventProperties={{ search_term: title }}
        className={cn(
          { "mt-3": index !== 0 },
          "label-sm hover:bg-overlay-inverse100"
        )}
        onDismiss={onDismiss ? () => onDismiss?.(item) : undefined}
        onClick={onClick ? () => onClick?.(item) : undefined}>
        {item}
      </DismissableItem>
    ))}
  </div>
);

interface SearchListsProps {
  popularSearches?: string[];
  savedSearches?: string[];
  recentSearches?: string[];
  className?: string;
}

const SearchLists: React.FC<SearchListsProps> = (props: SearchListsProps) => {
  const { popularSearches, recentSearches, className } = props;
  const router = useRouter();
  const client = useApolloClient();
  const { isLinkPastingEnabled } = useLinkPasting();

  // For beta, we'll use a combo of recent and popular searches
  // For launch, we'll have saved searches instead of popular
  // TODO: PHI-988 : Add the ability to save your search
  // TODO: PHI-986 : Display recent searches in search overlay
  const handlePopularSearchClick = (searchTerm: string) => {
    // Reset cache for products and editorial products query
    resetCacheForQuery(client, "loadProductsWithFallbacks");
    resetCacheForQuery(client, "products");
    const queryParam =
      isValidUrl(searchTerm) && isLinkPastingEnabled
        ? ProductSearchParams.LINK
        : ProductSearchParams.SEARCH;
    router.push(
      `${routes.PRODUCTS}?${queryParam}=${encodeURIComponent(searchTerm)}`
    );
  };

  const handleDismissRecentSearch = (searchTerm: string) => {
    removeRecentSearch(searchTerm);
  };

  return (
    <section
      className={cn("flex flex-col gap-4 sm:flex-row sm:pt-0", className)}>
      {recentSearches?.length ? (
        <SearchListColumn
          eventName={MixpanelEventName.SEARCH_OVERLAY_RECENT_SEARCH_CLICKED}
          onDismiss={handleDismissRecentSearch}
          title="Recent searches"
          items={recentSearches.slice(0, MAX_SEARCH_ITEMS)}
          onClick={handlePopularSearchClick}
        />
      ) : null}
      {popularSearches?.length ? (
        <SearchListColumn
          eventName={MixpanelEventName.SEARCH_OVERLAY_POPULAR_SEARCH_CLICKED}
          title="Popular searches"
          items={popularSearches.slice(0, MAX_SEARCH_ITEMS)}
          onClick={handlePopularSearchClick}
        />
      ) : null}
    </section>
  );
};

export default SearchLists;
