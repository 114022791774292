import { EditorialPage } from "@/__generated__/graphql";
import Link from "@/components/shared/Link";
import { routes } from "@/routes";
import { MixpanelEventName } from "@/types";
import { Badge, Label } from "@phiaco/phia-ui";
import Image from "next/image";

interface EditorialGridProps {
  editorials: Array<EditorialPage>;
  heading?: string;
}

const EditorialGrid: React.FC<EditorialGridProps> = ({
  editorials,
  heading,
}) => {
  return (
    <div>
      <Label size="md" className="pb-4 sm:py-4">
        {heading && heading.length ? heading : "Popular looks"}
      </Label>
      <div className="no-scrollbar mx-auto grid w-full grid-cols-2 gap-4 overflow-x-scroll sm:grid-cols-4">
        {editorials.map(edit => {
          const tileImage = edit?.image;

          if (!tileImage) {
            return null;
          }

          const url = routes.EDITORIALS + edit.slug;

          return (
            <Link
              key={edit.slug}
              eventName={MixpanelEventName.SEARCH_OVERLAY_EDITORIAL_CLICKED}
              eventProperties={{ slug: url }}
              className="flex"
              href={url}>
              <div className="relative h-[108px] w-full overflow-hidden rounded-sm sm:h-[157px] sm:w-[213px]">
                <Image
                  src={tileImage.url}
                  alt={tileImage.alt ?? ""}
                  width={213}
                  height={157}
                  className="h-full w-full object-cover hover:bg-overlay-inverse100"
                  unoptimized
                />
                <div className="overlay absolute inset-0 hover:bg-overlay-inverse100" />
                {edit?.label ? (
                  <Badge
                    type="overlay"
                    label={edit.label}
                    size="sm"
                    className="absolute bottom-0 left-0 m-3"
                  />
                ) : null}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default EditorialGrid;
