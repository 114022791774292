import { Category } from "@/__generated__/graphql";
import { getCategoryMenu } from "@/api/contentful/getCategoryMenu";
import { contentfulClient as client } from "@/lib/contentful/contentfulApolloClient";
import {
  setCategoryMenuDataState,
  useCategoryMenuDataState,
} from "@/lib/graphql/state";
import { MixpanelEventName } from "@/types";
import { useEffect, useState } from "react";
import useAnalytics from "./useAnalytics";
import { useLinkPasting } from "./useLinkPasting";

function useCategoryData() {
  const { isLinkPastingEnabled } = useLinkPasting();

  const cachedCategoryData = useCategoryMenuDataState(); // Access the reactive variable
  const [categoryData, setCategoryData] = useState<Array<Category>>(
    cachedCategoryData ?? []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { logUserEventToMixpanel } = useAnalytics();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { categories } = await getCategoryMenu(client);
        logUserEventToMixpanel({
          name: MixpanelEventName.CATEGORY_MENU_PILLS_SHOWN,
          properties: {
            category_names: categories.map(category => category.label),
          },
        });
        setCategoryData(categories ?? []);
        setCategoryMenuDataState(categories ?? []);
        setIsLoading(false);
      } catch (e) {
        console.error("An error occurred while fetching the data: ", e);
        setError(e as Error);
      }
    };

    if (!cachedCategoryData?.length && !isLinkPastingEnabled) {
      fetchData();
    }

    // Intentionally omit dependencies to avoid unnecessary calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { categoryData, isLoading, error };
}

export default useCategoryData;
