import { Platform } from "@/__generated__/graphql";
import { zIndex } from "@/constants";
import { Modal } from "@phiaco/phia-ui";
import SignInContent from "../SignInContent";

const SignInModal = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  // TODO [PHI-1361 : Add image to sign in modal]
  return (
    <Modal
      isOpen={isOpen}
      zIndex={zIndex.MODAL_OVERLAY}
      onClose={onClose}
      overlayClassName="bg-opacity-0"
      className="w-[calc(100vw_-_1.5rem)] pb-[0px] pl-[0px] pr-[0px] sm:w-auto sm:px-0 sm:py-0">
      <SignInContent onboardingSource={Platform.MarketplaceWeb} />
    </Modal>
  );
};
export default SignInModal;
