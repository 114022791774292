"use client";

import Footer from "@/components/Footer";
import Header from "@/components/Header/Header";
import {
  EXTENSION_IFRAME_ROUTES,
  routes,
  ROUTES_WITHOUT_LAYOUT,
} from "@/routes";
import "cropperjs/dist/cropper.css";
import "@phiaco/phia-ui/dist/styles.css";
import { matchRoute } from "@phiaco/phia-ui/dist/util";
import classNames from "classnames";
import { Inter } from "next/font/google";
import { usePathname } from "next/navigation";
import "../pages/globals.css";
import BackToTop from "@/components/BackToTop";
import LayoutSpacer from "@/components/LayoutSpacer";
import NoSSR from "@/components/NoSSR";
import {
  PHIA_COOKIE_ID,
  PHIA_COOKIE_MIXPANEL_DEVICE_ID,
  PHIA_COOKIE_MIXPANEL_DISTINCT_ID,
} from "@/constants";
import AuthProvider from "@/context/AuthContext";
import { SearchProvider } from "@/context/SearchContext";
import StatsigProvider from "@/context/StatsigProvider";
import { useInternalEmployeeStatus } from "@/hooks/useInternalEmployeeStatus";
import { init as initFullStory } from "@fullstory/browser";
import { ToastProvider } from "@phiaco/phia-ui";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Cookies from "js-cookie";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";

const FULL_STORY_ORG_ID = process.env.NEXT_PUBLIC_FULL_STORY_ORG_ID ?? "";
const INTER = Inter({ subsets: ["latin"] });

export default function AppLayout({ children }: { children: React.ReactNode }) {
  const pathname = usePathname() ?? "";
  // Set the state to determine internal employee status when user is unauthed
  // TODO PHIA-2514: Remove old auth experience code and feature flag
  useInternalEmployeeStatus();

  // TODO: PHI-2054 Referral sign up route should match as public route
  const includeLayout =
    !matchRoute(pathname, ROUTES_WITHOUT_LAYOUT) &&
    !pathname.startsWith(routes.REFERRAL_SIGNUP);
  // If the route is an extension iframe route, we don't want to avoid using an auth context.
  const isExtensionIframeRoute = matchRoute(pathname, EXTENSION_IFRAME_ROUTES);

  useEffect(() => {
    const defaultCookieOptions: Cookies.CookieAttributes = {
      domain: ".phia.com",
      path: "/",
      secure: true,
      sameSite: "Lax",
    };

    // Identify logged in user for Mixpanel, Sentry, and FullStory
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN ?? "", {
      debug: process.env.NODE_ENV === "development",
      persistence: "localStorage",
    });

    Cookies.set(
      PHIA_COOKIE_MIXPANEL_DEVICE_ID,
      mixpanel.get_property("$device_id"),
      defaultCookieOptions
    );
    Cookies.set(
      PHIA_COOKIE_MIXPANEL_DISTINCT_ID,
      mixpanel.get_property("distinct_id"),
      defaultCookieOptions
    );

    let phiaId = Cookies.get(PHIA_COOKIE_ID);
    if (!phiaId) {
      phiaId = crypto.randomUUID();
      Cookies.set(PHIA_COOKIE_ID, phiaId, defaultCookieOptions);
    }

    if (process.env.NEXT_PUBLIC_VERCEL_ENV === "production") {
      initFullStory({
        orgId: FULL_STORY_ORG_ID,
      });
    }
  }, []);

  return (
    <NoSSR>
      <main className={classNames(INTER.className, "antialiased")}>
        <GoogleOAuthProvider
          clientId={
            process.env.NEXT_PUBLIC_FIREBASE_SERVICE_ACCOUNT_CLIENT_ID ?? ""
          }>
          <ToastProvider>
            {isExtensionIframeRoute ? (
              <StatsigProvider>{children}</StatsigProvider>
            ) : (
              <AuthProvider>
                <StatsigProvider>
                  <SearchProvider>
                    {includeLayout && <LayoutSpacer />}
                    {includeLayout && <Header />}
                    {children}
                    {includeLayout && <Footer />}
                    <BackToTop />
                  </SearchProvider>
                </StatsigProvider>
              </AuthProvider>
            )}
          </ToastProvider>
        </GoogleOAuthProvider>
      </main>
    </NoSSR>
  );
}
