import { BrandPage } from "@/__generated__/graphql";
import Link from "@/components/shared/Link";
import { routes } from "@/routes";
import { MixpanelEventName } from "@/types";
import { Label } from "@phiaco/phia-ui";
import cn from "classnames";
import Image from "next/image";
import { useState } from "react";

interface BrandGridProps {
  brands: Array<BrandPage>;
  heading?: string;
}

interface BrandTileProps {
  handleClick?: () => void;
  brand: BrandPage;
}

const BrandTile: React.FC<BrandTileProps> = ({ handleClick, brand }) => {
  const tileImage = brand.brandTile ?? brand.imagesCollection.items[0];
  const [isHovering, setIsHovering] = useState(false);

  if (!tileImage) {
    return null;
  }

  const url = routes.BRAND + brand.slug;
  return (
    <Link
      key={brand.slug}
      eventName={MixpanelEventName.SEARCH_OVERLAY_BRAND_CLICKED}
      eventProperties={{ slug: url }}
      className="flex min-w-[105px]"
      href={url}
      onClick={handleClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}>
      <div className="relative h-[108px] w-full overflow-hidden rounded-sm sm:h-[243px] sm:w-[136px]">
        <Image
          src={tileImage.url}
          alt={tileImage.alt ?? brand.heading}
          width={136}
          height={243}
          className="h-full w-full object-cover"
          unoptimized
        />
        <div
          className={cn(
            "absolute inset-0 h-full w-full bg-overlay-inverse200",
            { "opacity-20": isHovering }
          )}
        />
        <div className="display-xxs absolute inset-0	mx-auto flex h-full w-full items-center justify-center p-4 text-center text-base font-medium text-cn-onColor">
          {brand.heading}
        </div>
      </div>
    </Link>
  );
};

const BrandGrid: React.FC<BrandGridProps> = ({ brands, heading }) => {
  return (
    <div className="pb-6">
      <Label size="md" className="py-4">
        {heading && heading.length ? heading : "Popular brands"}
      </Label>
      <div className="no-scrollbar mx-auto flex w-full gap-4 overflow-x-scroll">
        {brands.map(brand => {
          return <BrandTile key={brand.slug} brand={brand} />;
        })}
      </div>
    </div>
  );
};

export default BrandGrid;
