import { Badge } from "@phiaco/phia-ui";

const BetaBadge = () => (
  <Badge
    type="transparent"
    label="Beta"
    className="py-0.5"
    textClassName="label-xs text-[0.75rem]"
  />
);

export default BetaBadge;
