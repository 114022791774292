import useAnalytics from "@/hooks/useAnalytics";
import { LogEventProperties, MixpanelEventName } from "@/types";
// eslint-disable-next-line no-restricted-imports
import SharedLink, { LinkProps as SharedLinkProps } from "next/link";
import { MouseEventHandler } from "react";

export interface LinkProps extends SharedLinkProps {
  eventName: MixpanelEventName | null;
  eventProperties?: LogEventProperties;
  target?: HTMLAnchorElement["target"];
  onMouseLeave?: MouseEventHandler<HTMLAnchorElement> | undefined;
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const Link: React.FC<LinkProps & React.RefAttributes<HTMLAnchorElement>> = ({
  eventName,
  eventProperties,
  onClick,
  ...props
}) => {
  const { logMixpanelEvent } = useAnalytics();
  return (
    <SharedLink
      onClick={e => {
        onClick?.(e);
        if (eventName) {
          logMixpanelEvent({
            name: eventName,
            properties: eventProperties,
          });
        }
      }}
      {...props}
    />
  );
};

export default Link;
