import { Badge } from "@phiaco/phia-ui";
import cn from "classnames";
import Image from "next/image";
import * as React from "react";

interface Props {
  src: string;
  // The width and height attributes are used to infer the correct aspect ratio of image and avoid layout shift from the image loading in.
  // The width and height do not determine the rendered size of the image file.
  width?: number;
  height?: number;
  label?: string;
  className?: string;
}

const ImageTile: React.FC<Props> = ({
  className,
  label,
  src,
  width,
  height,
}) => (
  <div className={cn("relative h-full overflow-hidden rounded-sm ", className)}>
    <Image
      src={src}
      alt={label ?? ""}
      width={width}
      height={height}
      className="h-full w-full object-cover"
      priority={true}
      unoptimized
    />
    <div className="absolute inset-0 hover:bg-overlay-inverse100" />
    {label && (
      <Badge
        label={label}
        type="overlay"
        size="xs"
        className="absolute bottom-1 left-1 max-w-20 sm:bottom-3 sm:left-3 sm:max-w-[unset] [&>div]:truncate"
      />
    )}
  </div>
);

export default ImageTile;
