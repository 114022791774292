"use client";

import { getApolloClientSSR } from "@/lib/graphql/app/initApolloClient";
import { ApolloNextAppProvider } from "@apollo/experimental-nextjs-app-support";

export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return (
    <ApolloNextAppProvider makeClient={getApolloClientSSR}>
      {children}
    </ApolloNextAppProvider>
  );
}
