import ButtonLarge from "@/components/shared/ButtonLarge";
import { zIndex } from "@/constants";
import { MixpanelEventName } from "@/types";
import { DoorFancyIcon, Modal } from "@phiaco/phia-ui";

const SignOutModal = ({
  onClose,
  onSignOut,
  isOpen,
  isLoading,
}: {
  onClose: () => void;
  onSignOut: () => void;
  isOpen: boolean;
  isLoading: boolean;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      zIndex={zIndex.PRODUCT_MODAL}
      onClose={onClose}
      className="w-[393px] items-center gap-0 p-xl sm:w-[520px]">
      <div className="flex flex-col gap-8">
        <DoorFancyIcon className="mx-auto mt-4 sm:mt-10" />
        <h1 className="heading-serif-lg text-center">
          Are you sure you want to sign out?
        </h1>
        <div className="flex w-full flex-col gap-3">
          <ButtonLarge
            // We log this event in the event handler
            eventName={null}
            variant="secondary"
            onClick={onSignOut}
            isLoading={isLoading}
            textClassName="text-cn-negative">
            Yes, sign me out
          </ButtonLarge>
          <ButtonLarge
            eventName={MixpanelEventName.SIGN_OUT_CANCELLED}
            variant="secondary"
            onClick={onClose}>
            Go back
          </ButtonLarge>
        </div>
      </div>
    </Modal>
  );
};
export default SignOutModal;
