import { SearchParams } from "../_types";

// Function to compare two SearchParams objects
export function compareParams(
  draftParams: SearchParams,
  searchParams: SearchParams
): boolean {
  // Check if the number of keys is the same
  if (Object.keys(draftParams).length !== Object.keys(searchParams).length) {
    return false;
  }

  // Check if all keys and values are the same
  for (const key in draftParams) {
    if (draftParams[key] !== searchParams[key]) {
      return false;
    }
  }

  return true;
}

export function objectToSearchParams(obj: SearchParams): URLSearchParams {
  const params = new URLSearchParams();
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      params.append(key, obj[key]);
    }
  }
  return params;
}

export function searchParamsToObject(params: URLSearchParams): SearchParams {
  const result: SearchParams = {};
  params.forEach((value, key) => {
    result[key] = value;
  });
  return result;
}
