import useLayoutSpacing from "@/hooks/useLayoutSpacing";

const LayoutSpacer: React.FC = () => {
  const { headerHeight } = useLayoutSpacing();

  return (
    <div
      style={{
        height: headerHeight,
      }}
      className="w-full"
    />
  );
};

export default LayoutSpacer;
