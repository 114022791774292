import useAnalytics from "@/hooks/useAnalytics";
import { LogEventProperties, MixpanelEventName } from "@/types";
import React from "react";

interface Props {
  eventName: MixpanelEventName | null;
  eventProperties?: LogEventProperties;
  className?: string;
  children?: React.ReactNode;
}

const ExternalLink: React.FC<
  Props & React.AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ eventName, eventProperties, onClick, ...props }) => {
  const { logMixpanelEvent } = useAnalytics();
  return (
    <a
      onClick={e => {
        onClick?.(e);
        if (eventName) {
          logMixpanelEvent({
            name: eventName,
            properties: eventProperties,
          });
        }
      }}
      {...props}
    />
  );
};

export default ExternalLink;
