import { Category, GetCategoryMenuQuery } from "@/__generated__/graphql";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";

const categoryMenuQuery = gql`
  query GetCategoryMenu($id: String!) {
    categoryMenu(id: $id) {
      categoriesCollection(limit: 10) {
        items {
          label
          showcasedPagesCollection(limit: 5) {
            items {
              ... on CategoryPage {
                slug
                heading
                image {
                  url
                }
              }
              ... on EditorialPage {
                slug
                label
                image {
                  url
                }
              }
              ... on PartnerPage {
                slug
                partnerId
                heading
                imagesCollection(limit: 1) {
                  items {
                    url
                  }
                }
              }
              ... on BrandPage {
                slug
                brandId
                heading
                imagesCollection(limit: 1) {
                  items {
                    url
                  }
                }
              }
            }
          }
          subcategoriesCollection(limit: 10) {
            items {
              label
              categoryPage {
                ... on CategoryPage {
                  slug
                  heading
                  subheading
                  products
                }
                ... on EditorialPage {
                  slug
                  label
                  products
                }
                ... on PartnerPage {
                  slug
                  partnerId
                  heading
                  products
                }
                ... on BrandPage {
                  slug
                  brandId
                  heading
                  products
                }
              }
              optionsCollection(limit: 10) {
                items {
                  ... on CategoryPage {
                    slug
                    heading
                  }
                  ... on EditorialPage {
                    slug
                    label
                  }
                  ... on PartnerPage {
                    slug
                    partnerId
                    heading
                  }
                  ... on BrandPage {
                    slug
                    brandId
                    heading
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export async function getCategoryMenu(
  client: ApolloClient<NormalizedCacheObject>
): Promise<{
  isLoading: boolean;
  categories: Array<Category>;
}> {
  const queryOutput = await client.query<GetCategoryMenuQuery>({
    query: categoryMenuQuery,
    variables: {
      // For now, we only want to pull this category menu object
      id: "1hOuQ3veuXbXLFmSbc5ECY",
    },
  });
  return {
    isLoading: queryOutput.loading,
    categories:
      (queryOutput.data.categoryMenu.categoriesCollection
        .items as Category[]) ?? [],
  };
}
