"use client";

import { mediumTransition } from "@/app/_utils";
import BetaBadge from "@/components/Beta/BetaBadge";
import BetaFeedbackModal from "@/components/Beta/BetaFeedbackModal";
import Logo from "@/components/Logo";
import { zIndex } from "@/constants";
import { useSearch } from "@/context/SearchContext";
import useLayoutSpacing from "@/hooks/useLayoutSpacing";
import { useLinkPasting } from "@/hooks/useLinkPasting";
import { useHomeRevamp } from "@/hooks/useNewHomepage";
import usePhiaPathname from "@/hooks/usePhiaPathname";
import {
  routes,
  ROUTES_TO_HIDE_CATEGORY_MENU,
  ROUTES_TO_HIDE_SEARCH,
  ROUTES_WITH_BACK_BUTTON,
} from "@/routes";
import { MixpanelEventName } from "@/types";
import { ArrowLeftIcon, useTailwindBreakpoint } from "@phiaco/phia-ui";
import { customCN as cn, matchRoute } from "@phiaco/phia-ui/dist/util";
import { AnimatePresence, motion } from "framer-motion";
import dynamic from "next/dynamic";
import { useRouter } from "next/navigation";
import { useState } from "react";
import CategoryMenu from "../CategoryMenu/CategoryMenu";
import FeedbackModal from "../FeedbackModal";
import Button from "../shared/Button";
import CircleButton from "../shared/CircleButton";
import NavMenu from "./NavMenu";
import SearchOverlay from "./SearchOverlay/SearchOverlay";
import SearchOverlayContainer from "./SearchOverlay/SearchOverlayContainer";
import { overlayVariants } from "./SearchOverlay/utils";

// using dynamic with ssr: false to disable server side rendering on strictly client components
const SearchBar = dynamic(() => import("./SearchBar/SearchBar"), {
  ssr: false,
});

const Header = () => {
  const [isBetaFeedbackOpen, setIsBetaFeedbackOpen] = useState(false);
  const [isLeaveFeedbackOpen, setIsLeaveFeedbackOpen] = useState(false);
  const router = useRouter();
  const pathname = usePhiaPathname();
  const { screenSize, isMobile } = useTailwindBreakpoint();
  const { headerHeight, isScrolled, landingScrolled } = useLayoutSpacing();
  const { isSearchOverlayVisible: hasSearchOverlay, searchOverlayData } =
    useSearch();
  const { isRevampActive } = useHomeRevamp();
  const { isLinkPastingEnabled } = useLinkPasting();

  const handleLeaveFeedbackButtonClick = () => {
    setIsBetaFeedbackOpen(false);
    setIsLeaveFeedbackOpen(true);
  };

  const handleNestedProfileRouting = () => {
    // Special routing for profile
    if (pathname.startsWith(routes.PROFILE)) {
      router.push(routes.PROFILE);
      return;
    }
    router.back();
  };

  const onlyShowSearchBar = isScrolled && isMobile;

  const isLandingPageScrolled = landingScrolled && pathname === routes.HOME;

  const showSearchBar =
    (hasSearchOverlay && !isRevampActive) ||
    !matchRoute(pathname, ROUTES_TO_HIDE_SEARCH[screenSize]) ||
    isLandingPageScrolled;

  const showSearchbarInRevamped = !(
    isRevampActive &&
    (pathname === routes.HOME || pathname === "/homepage") &&
    !isScrolled
  );

  const showCategoryMenu =
    !hasSearchOverlay &&
    showSearchBar &&
    !matchRoute(pathname, ROUTES_TO_HIDE_CATEGORY_MENU[screenSize]);

  const canGoBack =
    ROUTES_WITH_BACK_BUTTON.some(route => pathname.startsWith(route)) &&
    !isLinkPastingEnabled;

  return (
    <header
      className={cn(
        "fixed left-0 top-0 flex w-full flex-col items-center justify-center py-4 sm:gap-3 sm:px-0 sm:py-4",
        // If the search bar is not the only element visible in the header, restore the gap and revert to the normal padding.
        { "gap-4 py-4": !onlyShowSearchBar },
        // Instead of removing the search bar's margin-top of 8px, we set the padding to 8px on the top and 16px on the bottom, creating the illusion that both are 16px.
        { "pb-4 pt-2": onlyShowSearchBar },
        isRevampActive
          ? "bg-[linear-gradient(180deg,_#fff_0%,_rgba(0,0,0,0.00)_100%)] backdrop-blur-xl sm:backdrop-blur-sm"
          : "bg-[rgba(255,255,255,0.7)] backdrop-blur-xl",
        hasSearchOverlay && (isRevampActive ? "" : "bg-white")
      )}
      style={{
        zIndex: zIndex.HEADER,
        minHeight: headerHeight,
      }}>
      <div className="w-full px-4 sm:flex">
        {/* Logo: DESKTOP */}
        <div className="hidden sm:block">
          {canGoBack ? (
            <CircleButton
              eventName={MixpanelEventName.BACK_ARROW_BUTTON_CLICKED}
              className="sm:absolute sm:left-8"
              variant="secondary"
              onClick={handleNestedProfileRouting}>
              <ArrowLeftIcon size={24} />
            </CircleButton>
          ) : (
            <div
              className={cn(
                "flex items-center gap-4 sm:absolute sm:left-8 ",
                isRevampActive ? "top-1/2 -translate-y-1/2" : "sm:top-5"
              )}>
              <Logo className="grow-0 fill-black" />
              <Button
                variant="transparent"
                innerClassName="p-0"
                eventName={MixpanelEventName.BETA_BUTTON_CLICKED_IN_NAV}
                onClick={() => setIsBetaFeedbackOpen(true)}>
                <BetaBadge />
              </Button>
            </div>
          )}
        </div>
        {/* Logo & nav menu: MOBILE  */}
        <AnimatePresence>
          {!onlyShowSearchBar && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              className={cn(
                "flex justify-between transition-transform duration-300 sm:hidden sm:pt-3"
              )}>
              {canGoBack ? (
                <CircleButton
                  eventName={MixpanelEventName.BACK_ARROW_BUTTON_CLICKED}
                  variant="secondary"
                  onClick={handleNestedProfileRouting}>
                  <ArrowLeftIcon />
                </CircleButton>
              ) : (
                <div className="flex items-center gap-4">
                  <Logo className="grow-0 fill-black" />
                  <BetaBadge />
                </div>
              )}
              <NavMenu
                isLandingPageScrolled={isLandingPageScrolled}
                setIsLeaveFeedbackOpen={setIsLeaveFeedbackOpen}
              />
            </motion.div>
          )}
        </AnimatePresence>

        {/* Search bar */}
        <div
          className={cn(
            "grow-1 mx-auto mt-2 w-full sm:mt-0",
            isRevampActive && "max-w-[700px] sm:max-w-[400px] lg:min-w-[650px]"
          )}>
          {showSearchbarInRevamped && showSearchBar && (
            <div className="relative flex flex-col">
              <SearchBar
                className={cn(
                  "mx-auto w-full max-w-[700px] sm:max-w-[400px] sm:p-0 lg:min-w-[650px]",
                  isLinkPastingEnabled ? "para-sm text-[16px]" : "pl-3",
                  hasSearchOverlay && isRevampActive && "rounded-b-none",
                  isRevampActive && "border-none transition-all"
                )}
                isHeader
                focusOnMount={
                  isRevampActive ? isLandingPageScrolled : hasSearchOverlay
                }
                size="sm"
              />
              {isRevampActive && (
                <div
                  className={cn("absolute top-[56px] w-full")}
                  style={{
                    zIndex: zIndex.HEADER - 1,
                  }}>
                  <SearchOverlayContainer className="border-border-transparent" />
                </div>
              )}
            </div>
          )}
        </div>

        {/* Nav menu: DESKTOP */}
        <div
          className={cn(
            "right-8 hidden gap-4 sm:absolute sm:flex",
            isRevampActive ? "top-1/2 -translate-y-1/2" : "top-5"
          )}
          style={{ zIndex: zIndex.HEADER + 1 }}>
          <NavMenu
            isLandingPageScrolled={isLandingPageScrolled}
            setIsLeaveFeedbackOpen={setIsLeaveFeedbackOpen}
          />
        </div>
      </div>
      <AnimatePresence mode="wait">
        {!onlyShowSearchBar && showCategoryMenu && !isLinkPastingEnabled && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: !isLinkPastingEnabled ? 1 : 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: isMobile ? 0.3 : 0 }}
            className="w-full">
            <CategoryMenu />
          </motion.div>
        )}
      </AnimatePresence>
      {/* Animate Presence will make sure to include exit animation, but we have to use it before condition to function properly */}
      <AnimatePresence>
        {!isRevampActive &&
          hasSearchOverlay &&
          searchOverlayData !== undefined && (
            // Had to add this extra div so search overlay comes behind search bar
            <div className="w-full overflow-hidden">
              <motion.div
                key="searchOverlay"
                variants={overlayVariants}
                // This custom can be utilised in variants
                custom={isMobile}
                initial="hidden"
                animate="visible"
                exit="exit"
                // Adding medium transition here for feasability
                transition={mediumTransition}
                className="w-full bg-white">
                <SearchOverlay searchOverlay={searchOverlayData} />
              </motion.div>
            </div>
          )}
      </AnimatePresence>
      <BetaFeedbackModal
        isOpen={isBetaFeedbackOpen}
        setIsOpen={setIsBetaFeedbackOpen}
        onLeaveFeedbackClicked={handleLeaveFeedbackButtonClick}
      />
      <FeedbackModal
        onClose={() => setIsLeaveFeedbackOpen(false)}
        isOpen={isLeaveFeedbackOpen}
        className="sm:max-w-[500px]"
      />
    </header>
  );
};

export default Header;
