import {
  GetCurrentUserQuery,
  IsLoggedInQuery,
  IsLoggedInQueryVariables,
} from "@/__generated__/graphql";
import { IS_LOGGED_IN_QUERY } from "@/api/auth";
import { GET_CURRENT_USER_QUERY } from "@/api/settings";
import useKillswitch from "@/hooks/useKillswitch";
import { useInternalUserState } from "@/lib/graphql/state";
import { useQuery } from "@apollo/client";
import { StatsigClient, StatsigUser } from "@statsig/js-client";
import { StatsigProvider as ReactStatsigProvider } from "@statsig/react-bindings";
import { useMemo, type PropsWithChildren } from "react";

const clientSdkKey = process.env.NEXT_PUBLIC_STATSIG_CLIENT_KEY ?? "";

export default function StatsigProvider({ children }: PropsWithChildren) {
  const internalUserEmail = useInternalUserState();
  const { data: isLoggedInData } = useQuery<
    IsLoggedInQuery,
    IsLoggedInQueryVariables
  >(IS_LOGGED_IN_QUERY);
  const { data } = useQuery<GetCurrentUserQuery>(GET_CURRENT_USER_QUERY, {
    skip: !isLoggedInData?.isLoggedIn,
  });

  const client = useMemo(() => {
    const statsigUser: StatsigUser = {
      userID: data?.currentUser?.id ?? "",
      email: data?.currentUser?.email ?? internalUserEmail ?? "",
    };
    const client = new StatsigClient(clientSdkKey, statsigUser);
    client.initializeSync();
    return client;
  }, [data?.currentUser, internalUserEmail]);

  useKillswitch(client);

  return (
    <ReactStatsigProvider client={client}>{children}</ReactStatsigProvider>
  );
}
