import { Category, FeaturedPage, ShowcasedPage } from "@/__generated__/graphql";
import NextLink, { LinkProps } from "@/components/shared/Link";
import useAnalytics from "@/hooks/useAnalytics";
import { MixpanelEventName } from "@/types";
import { useTailwindBreakpoint } from "@phiaco/phia-ui";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import cn from "classnames";
import { useEffect } from "react";
import ImageTile from "./ImageTile";
import {
  getFeaturedPageHref,
  getShowcasedPageImage,
  getShowcasedPageLabel,
} from "./utils";

// Link component to be used when category menu overlay is triggered from menu
const NavigationMenuLink: React.FC<
  LinkProps & React.RefAttributes<HTMLAnchorElement>
> = ({ className, href, ...props }) => {
  return (
    <NavigationMenu.Link asChild>
      <NextLink className={className} href={href} {...props} />
    </NavigationMenu.Link>
  );
};

// Link component to be used when category menu overlay is triggered from footer (not via overlay)
const MenuLink: React.FC<
  LinkProps & React.RefAttributes<HTMLAnchorElement>
> = ({ className, href, ...props }) => {
  return <NextLink className={className} href={href} {...props} />;
};

const ImageTileComponent = ({
  category,
  placement,
  onClose = () => {}, // On Close to do nothing on desktop and close dialog on mobile
}: {
  category: Category;
  placement: "menu" | "footer";
  onClose?: () => void;
}) => {
  const itemsWithImage = category?.showcasedPagesCollection?.items?.filter(
    (page: ShowcasedPage) => {
      const image = getShowcasedPageImage(page);
      const href = getFeaturedPageHref(page);
      if (!image || !href) {
        return false;
      }
      return true;
    }
  );

  if (
    !category?.showcasedPagesCollection?.items?.length ||
    !itemsWithImage?.length
  )
    return null;

  const LinkComponent = placement === "menu" ? NavigationMenuLink : MenuLink;

  return (
    <div className="no-scrollbar flex w-full auto-cols-auto grid-flow-col grid-rows-2 gap-2 overflow-x-scroll lg:grid lg:overflow-visible xl:grid-rows-1 xl:gap-3">
      {category.showcasedPagesCollection?.items.map(
        (page: ShowcasedPage, index: number) => {
          const label = getShowcasedPageLabel(page);
          const image = getShowcasedPageImage(page);
          const href = getFeaturedPageHref(page);

          if (!image || !href || !label) {
            return null;
          }
          return (
            <LinkComponent
              key={`featuredPage-${index}`}
              eventName={MixpanelEventName.CATEGORY_MENU_FEATURED_PAGE_CLICKED}
              eventProperties={{ slug: href, label }}
              href={href}
              // Trigger close on link click
              onClick={onClose}>
              <ImageTile
                label={label ?? undefined}
                className="h-[156px] w-[100px] md:h-[156px] md:w-[156px] xl:h-[230px] xl:w-[150px]"
                src={image.url}
                height={230}
                width={155}
              />
            </LinkComponent>
          );
        }
      )}
    </div>
  );
};

interface OverlayMenuProps {
  category: Category;
  overlayClassName?: string;
  overrideLabel?: string;
  placement: "menu" | "footer";
  onClose?: () => void;
}

const CategoryOverlayMenu: React.FC<OverlayMenuProps> = ({
  category,
  overlayClassName,
  overrideLabel,
  placement,
  onClose = () => {}, // On Close to do nothing on desktop and close dialog on mobile
}) => {
  const { isMobile } = useTailwindBreakpoint();
  const { logMixpanelEvent } = useAnalytics();

  const subCategories = category?.subcategoriesCollection?.items;
  const subCategoriesCollection =
    isMobile && subCategories?.length
      ? [...subCategories].reverse()
      : subCategories;
  useEffect(() => {
    if (!category || !category?.label) {
      return;
    }
    logMixpanelEvent({
      name: MixpanelEventName.CATEGORY_MENU_PILL_HOVER,
      properties: { category: category?.label ?? "", placement },
    });
  }, [category, logMixpanelEvent, placement]);

  const LinkComponent = placement === "menu" ? NavigationMenuLink : MenuLink;

  return (
    <div
      className={cn(
        // Only show border bottom on desktop
        "border-opaque absolute top-8 flex w-full flex-col gap-6 bg-white px-6 py-10 pt-4 md:border-b md:px-10",
        overlayClassName
      )}>
      <div className="mx-auto flex w-full flex-col items-center justify-center gap-8 overflow-hidden sm:min-w-[600px]">
        {category?.label || overrideLabel ? (
          <div className="heading-serif-md w-full">
            {overrideLabel ? overrideLabel : category.label}
          </div>
        ) : null}
        <div
          className="flex h-full w-full auto-cols-auto flex-col-reverse flex-wrap gap-4 text-left lg:grid lg:max-h-[400px] lg:grid-flow-col"
          style={{
            gridTemplateColumns: "repeat(auto-fill, minmax(125px, 1fr))",
          }}>
          {subCategoriesCollection?.map((subcategory, index) => {
            const subcategoryLink = subcategory.categoryPage
              ? getFeaturedPageHref(subcategory.categoryPage)
              : undefined;
            return (
              <div
                key={`subcategories-${index}`}
                className="flex flex-col justify-between gap-4">
                <div className="flex flex-col gap-4">
                  {subcategory.categoryPage && subcategory.label ? (
                    <LinkComponent
                      eventName={
                        MixpanelEventName.CATEGORY_MENU_OVERLAY_PAGE_CLICKED
                      }
                      eventProperties={{ slug: subcategoryLink }}
                      href={subcategoryLink ?? ""}
                      // Trigger close on link click
                      onClick={onClose}
                      className="label-sm hover:font-medium">
                      {subcategory.label}
                    </LinkComponent>
                  ) : null}
                  <ul className="flex flex-col gap-2">
                    {subcategory.optionsCollection.items.map(
                      (page: FeaturedPage, index: number) => {
                        const link = getFeaturedPageHref(page);
                        return (
                          <LinkComponent
                            eventName={
                              MixpanelEventName.CATEGORY_MENU_OVERLAY_PAGE_CLICKED
                            }
                            eventProperties={{ slug: link }}
                            href={link ?? ""}
                            // Trigger close on link click
                            onClick={onClose}
                            className="para-sm hover:font-medium"
                            key={`subcategory-${index}`}>
                            {page.heading}
                          </LinkComponent>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            );
          })}
          <ImageTileComponent
            category={category}
            placement={placement}
            // Trigger close on link click
            onClose={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default CategoryOverlayMenu;
