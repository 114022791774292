// Created a custom Giveaway Tooltip to avoid default tooltip behavior i.e. showing on hover + styles are different for this one.
import { zIndex } from "@/constants";
import cn from "classnames";
import { motion } from "framer-motion";
import React, { useEffect, useRef } from "react";

interface GiveawayTooltipProps {
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
}

export const GiveawayTooltip = ({
  isVisible,
  setIsVisible,
}: GiveawayTooltipProps): JSX.Element => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsVisible]);

  return (
    <>
      {isVisible && (
        <motion.div
          ref={tooltipRef}
          initial={{ opacity: 0, scale: 0.95, x: 32 }}
          animate={{ opacity: 1, scale: 1, x: 32 }}
          exit={{ opacity: 0, scale: 0.95, x: 32 }}
          transition={{ duration: 0.2 }}
          className={cn(
            "border-opaque absolute right-full top-[150%] w-[190px] -translate-x-4 rounded-sm bg-cn-primary p-2 text-sm shadow-[0px_4px_16px_0px_rgba(0,0,0,0.12)]"
          )}
          style={{ zIndex: zIndex.HEADER + 2 }}>
          <div className="absolute right-2 top-[-6px] h-0 w-0 transform border-b-8 border-l-8 border-r-8 border-b-cn-primary border-l-transparent border-r-transparent" />
          <p className="para-ed-sm text-center text-white">
            Check out your giveaway entries here.
          </p>
        </motion.div>
      )}
    </>
  );
};

export default GiveawayTooltip;
