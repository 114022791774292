import { routes } from "@/routes";
import { StatsigClient } from "@statsig/js-client";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import usePhiaPathname from "./usePhiaPathname";

const useKillswitch = (client: StatsigClient) => {
  const router = useRouter();
  const pathname = usePhiaPathname();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await client.getDynamicConfig("website_killswitch");
        const isEnabled = config?.value?.isEnabled;
        if (isEnabled) {
          router.push(routes.OFFLINE);
        } else if (!isEnabled && pathname.includes(routes.OFFLINE)) {
          router.push(routes.HOME);
        }
      } catch (error) {
        console.error("Error fetching killswitch config:", error);
      }
    };

    fetchConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);
};

export default useKillswitch;
