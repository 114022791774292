import Logo from "@/components/Logo";
import ButtonLarge from "@/components/shared/ButtonLarge";
import { zIndex } from "@/constants";
import useAnalytics from "@/hooks/useAnalytics";
import { MixpanelEventName } from "@/types";
import { Badge, Modal } from "@phiaco/phia-ui";
import * as React from "react";

interface BetaFeedbackModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onLeaveFeedbackClicked: () => void;
}

const BetaFeedbackModal: React.FC<BetaFeedbackModalProps> = ({
  isOpen,
  setIsOpen,
  onLeaveFeedbackClicked,
}) => {
  const { logMixpanelEvent } = useAnalytics();

  const handleClose = () => {
    logMixpanelEvent({
      name: MixpanelEventName.BETA_FEEDBACK_CLOSED,
    });
    setIsOpen(false);
  };

  return (
    <Modal
      zIndex={zIndex.MODAL_OVERLAY}
      isOpen={isOpen}
      heading={
        <div className="flex items-center gap-4">
          <Logo className="h-12 w-12 fill-black" />
          <Badge
            type="transparent"
            label="Beta"
            className="py-0.5"
            textClassName="label-xs text-[0.75rem]"
          />
        </div>
      }
      includeCloseBtn={true}
      className="w-full sm:max-w-[500px]"
      onClose={handleClose}>
      <div className="relative flex h-fit flex-col gap-8 ">
        <div className="para-md text-center">
          We&apos;re excited to share the first iteration of this product with
          you! Your feedback is invaluable to us. Tell us what you love and
          where we can improve as we prepare for our official launch.
        </div>
        <ButtonLarge
          eventName={MixpanelEventName.BETA_LEAVE_FEEDBACK_CLICKED}
          variant="secondary"
          className="w-full"
          onClick={onLeaveFeedbackClicked}>
          Leave Feedback
        </ButtonLarge>
      </div>
    </Modal>
  );
};

export default BetaFeedbackModal;
