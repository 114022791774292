import { HTMLMotionProps, Transition } from "framer-motion";

export const fastTransition: Transition = {
  duration: 0.3,
  bounce: 0,
  ease: "easeInOut",
};

export const mediumTransition: Transition = {
  duration: 0.5,
  bounce: 0,
  ease: "easeInOut",
};

export const slowTransition: Transition = {
  duration: 0.8,
  bounce: 0,
  ease: "easeInOut",
};

export const floatIn: HTMLMotionProps<"div"> = {
  initial: {
    translateY: "10%",
    opacity: 0,
  },
  animate: {
    opacity: 1,
    translateY: "0%",
    transition: mediumTransition,
  },
};

export const fade: HTMLMotionProps<"div"> = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: fastTransition,
  },
  exit: {
    opacity: 0,
    transition: fastTransition,
  },
};

export const slideIn: HTMLMotionProps<"div"> = {
  initial: {
    translateX: "100%",
    opacity: 0,
  },
  animate: {
    opacity: 1,
    translateX: "0%",
    transition: mediumTransition,
  },
};

export const compressWidth: HTMLMotionProps<"div"> = {
  animate: {
    width: 0,
    transition: slowTransition,
  },
};

export const compressWidthExit: HTMLMotionProps<"div"> = {
  exit: {
    width: 0,
    transition: slowTransition,
  },
};

export const slideOutExit: HTMLMotionProps<"div"> = {
  exit: {
    translateX: "-100%",
    transition: slowTransition,
  },
};

export const fadeInTop: HTMLMotionProps<"div"> = {
  initial: {
    opacity: 0,
    translateY: "-25%",
  },
  animate: {
    opacity: 1,
    translateY: "0%",
    transition: mediumTransition,
  },
};

export const delayedFloatIn: HTMLMotionProps<"div"> = {
  initial: {
    opacity: 0,
    translateY: "10%",
  },
  animate: {
    opacity: 1,
    translateY: "0%",
  },
  transition: {
    delay: 1,
    damping: 30,
    bounce: 0,
    ease: "easeInOut",
    duration: 0.5,
  },
};

export const repositionCenterExit: HTMLMotionProps<"div"> = {
  initial: {
    translateX: "-15%",
    opacity: 1,
  },
  exit: {
    translateX: "0%",
    opacity: 0,
  },
  transition: fastTransition,
};
