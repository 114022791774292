// hooks/useInternalEmployeeStatus.ts
import { setInternalUserState } from "@/lib/graphql/state";
import { isInternalEmployee } from "@phiaco/phia-ui/dist/util";
import { useEffect } from "react";

export const useInternalEmployeeStatus = () => {
  useEffect(() => {
    const fetchInternalEmployeeStatus = async () => {
      const response = await fetch("/api/check-internal");
      const data = await response.json();
      if (isInternalEmployee(data.internalEmployeeCookie)) {
        setInternalUserState(data.internalEmployeeCookie);
      }
    };

    fetchInternalEmployeeStatus();
  }, []);
};
